<template>
    <section>
        <h2>Meeting Schedule</h2>
        <p>
            During the fall semester of 2023-2024, we organize weekly CTF training sessions in a hybrid setting, which means they can be attended in-person and online*.
            These training sessions are open to all students from the University of Twente, but a background in computer science is highly recommended.
            This year, THS is kicking off with five beginner-friendly workshop sessions covering different CTF categories. After that, we will transition into a more flexible format, as in previous years.
            Below, we give an overview of the planned sessions.
        </p>
        <p>*See the THS mailing list for the Zoom link to join.</p>

        <hr>
        <table class="table table-hover">
            <thead>
                <th class="table-cell">Date</th>
                <th class="table-cell">Time</th>
                <th class="table-cell">Location</th>
                <th class="table-cell">Subject</th>
            </thead>
            <tbody>
                <tr>
                    <td>September 09, 2024</td>
                    <td>13:45 to 15:30</td>
                    <td>RA 4231 &amp; online*</td>
                    <td>Session - <i>Reverse</i></td>
                </tr>
                <tr>
                    <td>September 25, 2024</td>
                    <td>13:45 to 15:30</td>
                    <td>DR 102B &amp; online*</td>
                    <td>Session - <i>Web</i></td>
                </tr>
                <tr>
                    <td>October 02, 2024</td>
                    <td>15:45 to 17:30</td>
                    <td>RA 2503 &amp; online*</td>
                    <td>Session - <i>Pwn</i></td>
                </tr>
                <tr>
                    <td>October 07, 2024</td>
                    <td>13:45 to 15:30</td>
                    <td>RA 4231 &amp; online*</td>
                    <td>Session - <i>Crypto</i></td>
                </tr>
            </tbody>
        </table>
        You can subscribe to our google calendar:
        <div class="input-group mb-3">
            <input id="calendar-subscribe" type="text" class="form-control copy" aria-label="calendar invite" readonly value="https://calendar.google.com/calendar/u/3?cid=MWZ1aGxnZW1nb2VyOTJsa2M4c3Ria21hYm9AZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ">
            <div class="input-group-append">
                <button v-if="!copied.subscribe" class="input-group-text copy" id="invite-copy" @click="copyCalendar('calendar-subscribe')"><i class="fa fa-clipboard" style="margin-right:5px" aria-hidden="true"></i> Copy</button>
                <button v-else         class="input-group-text copy" id="invite-copy" @click="copyCalendar('calendar-subscribe')"><i class="fa fa-check"     style="margin-right:5px" aria-hidden="true"></i> Copied</button>
            </div>
        </div>

        Or use this link to download the latest .ics file that you can import manually:
        <div class="input-group mb-3">
            <input id="calendar-ics" type="text" class="form-control copy" aria-label="calendar invite" readonly value="https://calendar.google.com/calendar/ical/1fuhlgemgoer92lkc8stbkmabo%40group.calendar.google.com/public/basic.ics">
            <div class="input-group-append">
                <button v-if="!copied.ics" class="input-group-text copy" id="invite-copy" @click="copyCalendar('calendar-ics')"><i class="fa fa-clipboard" style="margin-right:5px" aria-hidden="true"></i> Copy</button>
                <button v-else         class="input-group-text copy" id="invite-copy" @click="copyCalendar('calendar-ics')"><i class="fa fa-check"     style="margin-right:5px" aria-hidden="true"></i> Copied</button>
            </div>
        </div>
    </section>

</template>

<script>
// Import components
// import [component name] from './[component name].vue';

// Export app
export default {
  name: 'HomepageSchedule',

  data() {
      return {
          copied: {
            subscribe: false,
            ics: false,
          },
      }
  },

  // Set components
  components: {
    // [component name],
},

  methods: {
    async copyCalendar(element) {
        // Get invite ID
        const text = document.getElementById(element).value;

        // Copy text to clipboard
        await navigator.clipboard.writeText(text);

        // Set all copied buttons to false
        for (var key in this.copied){
            this.copied[key] = false;
        }

        // Set copy button to 'Copied'
        this.copied[element.split('-')[1]] = true;

        // Wait for 3 seconds
        await new Promise(r => setTimeout(r, 3000));

        // Reset state of copy button
        this.copied[element.split('-')[1]] = false;
    }
  },

  // Change height to auto
  mounted(){
      document.body.style.height = "auto";
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .copy {
        font-size: 100%;
    }
</style>
