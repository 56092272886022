<template>
    <div class="container-fluid">
        <!-- About the THS -->
        <section id="about">
            <h1>Twente Hacking Squad</h1>
            <div class="row mt-3">
                <p>The Twente Hacking Squad (THS) is a team of UT students &amp; PhD students interested in computer security. 
                Interested? Join the mailing list to stay up-to-date with our sessions, or try one of our CTF challenges right away!</p>
            </div>
            <div class="d-flex mt-5 gap-3 align-items-center">
                <div class="">
                    <a class="btn btn-lg home-page-button" href="https://www.lists.utwente.nl/cgi-bin/wa.exe?SUBED1=THS&A=1" target="_blank">Join the mailing list</a>
                </div>
                <div class="">
                    or
                </div>
                <div class="">
                    <router-link :to="{ name: 'challenges' }" class="btn btn-lg home-page-button">Try a challenge</router-link>
                </div>
            </div>
            <div class="row">
                <p>Use your full name to register to the mailing list, e.g., "John Doe".</p>
            </div>
            <div class="row">
                <div class="col-md-9">
                    <p>
                        We have weekly meetings during which we try to improve our hacking skills.
                        In our meetings we regularly give workshops where we share our hacking expertise with each other in various areas, such as:
                        <ul>
                            <li>Cryptography</li>
                            <li>Forensics</li>
                            <li>General programming skills</li>
                            <li>Reverse engineering</li>
                            <li>Pwning</li>
                            <li>Web hacking</li>
                        </ul>
                        See the schedule below for an overview of dates for each session.
                        As a team, we also participate in CTF events from time to time.
                    </p>
                </div>
                <div class="col-md-3">
                    <img width=100% src="../../assets/logo_ths.svg" alt="THS logo">
                </div>
            </div>
        </section>

        <!-- Workshop Schedule -->
        <HomepageSchedule />

        <!-- Joining THS -->
        <section id="join">
            <h2>Joining the team</h2>
            <p>
                The types of hacking we do ranges from reverse engineering to forensics, crypto, web hacking, pwning and more.
                If you're interested in joining, feel free to drop by!
                Note that you don't have to have any previous hacking experience. We are all learning something new every session :).
                If you want to stay up to date with when the sessions will take place, please <a href="https://www.lists.utwente.nl/cgi-bin/wa.exe?SUBED1=THS&A=1">subscribe</a> to our mailing list.
                You can also <a rel="nofollow" href="https://mattermost.dacs.utwente.nl/signup_user_complete/?id=8f5qrf9jpj81fymcfy6i8c5ore&md=link&sbr=su" target="_blank">join our Mattermost server</a>! (@student.utwente.nl or @utwente.nl account required)
            </p>
        </section>

        <!-- People -->
        <section id="people">
            <h2>Who are we?</h2>
            <ul>
                <li><a href="https://conand.me" target="_blank">Andrea Continella</a></li>
                <li><a href="https://thijsvane.de" target="_blank">Thijs van Ede</a></li>
                <li><a href="https://people.utwente.nl/p.jakubeit" target="_blank">Philipp Jakubeit</a></li>
                <li><a href="https://people.utwente.nl/y.a.m.kortekaas" target="_blank">Yoep Kortekaas</a></li>
                <li><a href="https://people.utwente.nl/j.a.l.starink" target="_blank">Jerre Starink</a></li>
                <li><a href="https://people.utwente.nl/f.mazzone" target="_blank">Federico Mazzone</a></li>
                <li><a href="https://people.utwente.nl/c.gupta" target="_blank">Chakshu Gupta</a></li>
                <li><a href="https://people.utwente.nl/z.kucsvan" target="_blank">Zsolt Kucsván</a></li>
                <li><a href="https://jorik.xyz" target="_blank">Jorik van Nielen</a></li>
                <li><a href="https://marc.damie.eu" target="_blank">Marc Damie</a></li>
                <li><a href="https://people.utwente.nl/m.grella" target="_blank">Matteo Grella</a></li>
            </ul>
        </section>
    </div>
</template>

<script>
// Import components
import HomepageSchedule from './HomepageSchedule.vue';

// Export app
export default {
  name: 'HomepageMain',

  // Set components
  components: {
    HomepageSchedule,
},

  methods: {
      // No methods yet
  },

  // Change height to auto
  mounted(){
      document.body.style.height = "auto";
  },
}
</script>


<style lang="css" scoped>
    .home-page-button {
        background-color: #800080;
        margin-bottom: 10px;
        color: white;
        text-align: center;
        width: 100%;
        font-size: 15px;
    }
</style>